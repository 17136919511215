<template>
    <el-dropdown 
		size="small" 
		split-button 
		type="primary" 
		:hide-on-click="false" 
		placement="top" 
		max-height="400" 
		trigger="click"
		@visible-change="downHeader(true)" 
		@click="downExcel()"
	>
		导出表格
		<template #dropdown>
			<el-checkbox-group v-model="headerChe" @change="headerCheFun">
			<el-dropdown-menu>
				<el-dropdown-item v-for="(val,key) in headerList" :key="key">
					<el-checkbox :label="val" :value="key" />
				</el-dropdown-item>
			</el-dropdown-menu>
			</el-checkbox-group>
		</template>
	</el-dropdown>
</template>
  
<script>
import * as XLSX from 'xlsx';  
import { saveAs } from 'file-saver';
export default {
    name: 'pageListCxecl',

	components: {},

	props: {
        urlKey:{types:String,default:''},
        nameKey:{types:String,default:''},
        mianPageCou:{types:Number,default:0},
		name: { types: String, default: 'mianPage' }, // 组件名称
    },

    data(){

        return {

			headerArr:[],
			headerList:{},
			headerChe:[],
			headerLen:0,

        }
    },

    created(){},

    methods:{

		// 下载表格
		downExcel(){
			var that = this;
			var data = [];
			const pageObj = document.querySelector('.pageListCxeclNumber_'+that.name+that.mianPageCou);

			that.downHeader();

			that.headerChe.sort((a, b) => {
				return a - b;
			});

			// 示例数据和头部  
			var headerArrData = [];
			that.headerChe.forEach(val => {
				if(val < 0|| val >= that.headerLen){
					return false;
				}
				headerArrData.push(that.headerArr[val]);
			});
			data.push(headerArrData);

			var downData = that.getDataList(pageObj,data);
			var widthArr = that.getWidth(pageObj);
			that.downTable(downData,widthArr);

		},

		// 下载表格
		downTable(data,widthArr){
		
			// 创建一个工作表  
			const ws = XLSX.utils.aoa_to_sheet(data); // 注意这里使用 headers 参数
			ws['!cols'] = widthArr
		
			// 创建一个新的工作簿并添加工作表  
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, '作品名称');
		
			// 将工作簿写入 blob 对象  
			const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });  
		
			try {

				var excel_name = this.util.getDate('yyyy-MM-dd')

				saveAs(new Blob(
					[wbout],
					{
						type: 'application/octet-stream' 
					}
				), excel_name+'.xlsx');  
			} catch (e) {  
				if (typeof console !== 'undefined') console.error(e, wbout);  
			} 
		},

		downHeader(off = false){
			if(off || this.headerArr.length == 0){
				var downExcelList = this.util.localGet('downExcelList') || {};
				if(downExcelList[this.nameKey]){
					this.headerChe = downExcelList[this.nameKey];
				}

				this.getHeader();
			}
		},

		headerCheFun(){
			var downExcelList = this.util.localGet('downExcelList') || {};

			this.headerChe.sort((a, b) => {
				return a - b;
			});
			console.log('headerChe',this.headerChe)

			downExcelList[this.nameKey] = this.headerChe;
			this.util.localSet('downExcelList',downExcelList)
		},

		getHeader(){
			var that = this;

			const excelPage = document.querySelector('.pageListCxeclNumber_'+that.name+that.mianPageCou);

			var headerLen = 0;
			var headerArr = [];
			var headerChe = [];
			var headerList = {};
			// 如果 .excelPage 存在，继续查找 .el-table__header 下的 th 元素
			if (excelPage) {
				var header = excelPage.querySelector('.el-table__header');

				if(!header){
					header = excelPage.querySelector('.vxe-table--header');
				}

				if (header) {
					// 获取所有 th 元素
					const thElements = header.querySelectorAll('th');
					headerLen = thElements.length;
					// 打印 th 元素
					var keyIndex = 0;
					var valText = '';
					thElements.forEach((val,key) => {
						valText = val.textContent.trim();
						headerArr.push(valText);
						console.log('th元素',valText)
						if(valText && valText.length > 0 && valText != '操作'){
							// keyIndex = (parseInt(key) - 1) + '';
							keyIndex = key+'';
							headerList[keyIndex] = valText;
							headerChe.push(keyIndex)
						}
					});
				} else {
					console.log('未找到 .el-table__header 元素');
				}
			} else {
				console.log('未找到 .excelPage 元素');
			}

			that.headerArr = headerArr;
			if(that.headerChe.length == 0){
				that.headerChe = headerChe;
				that.headerCheFun();
			}
			that.headerList = headerList;
			that.headerLen = headerLen;
		},

		getWidth(excelPage){
			var widthArr = [];
			var that = this;

			// 如果 .excelPage 存在，继续查找 .el-table__header 下的 th 元素
			if (excelPage) {
				var way = 1;
				var header = excelPage.querySelector('.el-table__header');
				if(!header){
					way = 0;
					header = excelPage.querySelector('.vxe-table--header');
				}

				if (header) {
					// 获取所有 th 元素
					const col = header.querySelectorAll('col');
					// 打印 th 元素

					that.headerChe.forEach(val => {
						if(val < 0|| val >= that.headerLen){
							return false;
						}

						if(way){
							widthArr.push({
								wpx: col[val].getAttribute('width')
							});
						}else{
							widthArr.push({
								wpx: parseFloat(col[val].style.width)
							});
						}
					});

				} else {
					console.log('未找到 .el-table__header 元素');
				}
			} else {
				console.log('未找到 .excelPage 元素');
			}

			return widthArr;
		},

		getDataList(excelPage,dataList){
			var dataVal = [];
			var that = this;

			// 如果 .excelPage 存在，继续查找 .el-table__body 下的 th 元素
			if (excelPage) {
				var data = excelPage.querySelector('.el-table__body');
				if(!data){
					data = excelPage.querySelector('.vxe-table--body');
				}

				if (data) {
					// 获取所有 th 元素
					const trList = data.querySelectorAll('tr');
					// 打印 th 元素
					trList.forEach(tr => {
						dataVal = [];
						const tdList = tr.querySelectorAll('td');

						that.headerChe.forEach(val => {
							if(val < 0|| val >= that.headerLen){
								return false;
							}
							
							dataVal.push(tdList[val].textContent);
						});

						dataList.push(dataVal);
					});
				} else {
					console.log('未找到 .el-table__body 元素');
				}
			} else {
				console.log('未找到 .excelPage 元素');
			}

			return dataList;
		},
    }
  }
  </script>
  
  <style lang="less">
  </style>
  