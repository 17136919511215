<template>
	<el-button type="warning" @click="downTable()" size="small">下载表格</el-button>
</template>
  
<script>
export default {
    name: 'pageListCxecl',

	components: {},

	props: {
        pageObj: {types:Object,default:() => ({})},
        urlKey:{types:String,default:''},
        nameKey:{types:String,default:''},
        name:{types:String,default:'mianPage'},
        mianPageCou:{types:Number,default:0},
    },

    data(){

        return {

			headerArr:[],
			headerList:{},
			headerChe:[],
			headerLen:0,

        }
    },

    created(){},

    methods:{

		// 下载表格
		downTable(){

			console.log('下载表格',this.pageObj.$refs)
		
			this.pageObj.$refs[this.name+'TableObj'].openExport();
			
		},

    }
  }
  </script>
  
  <style lang="less">
  </style>
  