import { createApp } from 'vue'

// ...
import VxeTable from 'vxe-table';
import 'vxe-table/lib/style.css';

import { VxeUI } from 'vxe-pc-ui'
import 'vxe-pc-ui/lib/style.css'
import VxeUIPluginExportXLSX from '@vxe-ui/plugin-export-xlsx'
import ExcelJS from 'exceljs'

VxeUI.use(VxeUIPluginExportXLSX, {
  ExcelJS
})
// ...

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'


import App from '@/App.vue'

import router from '@/router'
import store from '@/store/index.js' // 导入store.js文件

import util from '@/util/util.js';

import '@/style/reset.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import mianPage from '@/components/coom/mianPage/mianPage.vue'
import imgItem from '@/components/coom/imgItem/imgItem.vue'
import subBut from '@/components/coom/subBut/subBut.vue'
import butDel from '@/components/coom/butDel/butDel.vue'

import axios from 'axios';
axios.defaults.withCredentials = true; // 允许携带跨域的cookie信息  

//注册路由
const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus,{
    locale: zhCn,
})
app.use(VxeUI);
app.use(VxeTable);

app.config.globalProperties.util = util;

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.component('mianPage', mianPage) // 注册全局组件  
app.component('imgItem', imgItem) // 注册全局组件  
app.component('subBut', subBut) // 注册全局组件  
app.component('butDel', butDel) // 注册全局组件  
app.mount('#app')

