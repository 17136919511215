<template>
	<div class="pageList" :class="'pageListNumber_' + name + mianPageCou" style="height: 100%;">
		<!-- 常规 -->
		<!-- :destroy-on-close="true"  -->
		<el-drawer v-model="show" :size="search.size" :direction="search.direction" title="搜索条件">
			<el-form ref="form" :model="dataObj" label-width="120px" label-position="right">
				<el-row :gutter="20">
					<!-- eq like dataTime_qujian data_qujian batch sum_max_min -->
					<template v-for="(val1, key1) in searchs" :key="key1">
						<el-col :span="6" v-if="val1.way == 'eq'">

							<el-form-item :label="val1.name">
								<el-select v-model="dataObj[key1]" :placeholder="val1.name" style="width: 100%"
									clearable>
									<el-option label="空" value="" />
									<el-option v-for="(val2, key2) in val1.data" :key="key2" :label="val2"
										:value="key2" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="6" v-if="val1.way == 'types'">

							<el-form-item :label="val1.name">

								<goodsType v-model:valueId="dataObj[key1]" :is_all="false"></goodsType>

							</el-form-item>
						</el-col>

						<el-col :span="6" v-else-if="val1.way == 'like'">
							<el-form-item :label="val1.name">
								<el-input v-if="val1.type == 'number'" type="number" v-model="dataObj[key1]"
									:placeholder="val1.name" />
								<el-input v-else v-model="dataObj[key1]" :placeholder="val1.name" />
							</el-form-item>
						</el-col>

						<el-col :span="6" v-else-if="val1.way == 'number'">
							<el-form-item :label="val1.name">
								<el-input type="number" v-model="dataObj[key1]" :placeholder="val1.name" />
							</el-form-item>
						</el-col>

						<el-col :span="12" v-else-if="val1.way == 'dataTime_qujian'">
							<el-form-item :label="val1.name">
								<el-date-picker v-model="dataObj[key1]" type="datetimerange" start-placeholder="开始日期时间"
									end-placeholder="结束日期时间" format="YYYY-MM-DD HH:mm:ss" date-format="YYYY-MM-DD"
									time-format="hh:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" />
							</el-form-item>
						</el-col>

						<el-col :span="6" v-else-if="val1.way == 'data_qujian'">
							<el-form-item :label="val1.name">
								<el-date-picker v-model="dataObj[key1]" type="daterange" start-placeholder="开始日期"
									end-placeholder="结束日期" date-format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
							</el-form-item>
						</el-col>

						<el-col :span="6" v-else-if="val1.way == 'batch'">
							<el-form-item :label="val1.name">
								<el-date-picker v-model="dataObj[key1]" type="date" placeholder="选择日期"
									date-format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
							</el-form-item>
						</el-col>

						<el-col :span="12" v-else-if="val1.way == 'sum_max_min'">

							<el-form-item :label="val1.name">
								<el-row>
									<el-col :span="10">
										<el-input-number v-model="dataObj[key1].min" placeholder="最小值为0则不限"
											style="width: 100%;" />
									</el-col>
									<el-col :span="4" style="text-align: center;">
										--
									</el-col>
									<el-col :span="10">
										<el-input-number v-model="dataObj[key1].max" placeholder="最大值为0则不限"
											style="width: 100%;" />
									</el-col>
								</el-row>
							</el-form-item>
						</el-col>

						<el-col :span="24" v-if="val1.trEnd == 1"></el-col>

					</template>
				</el-row>

				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item>
							<el-button type="primary" @click="onSubmit()">提交</el-button>
							<el-button @click="show = false">取消</el-button>
						</el-form-item>
					</el-col>
					<el-col :span="12" style="text-align: right;">
						<el-button type="primary" @click="onSubmit()">提交</el-button>
						<el-button @click="show = false">取消</el-button>
					</el-col>
				</el-row>

			</el-form>
		</el-drawer>

		<el-header class="df jcsb aic" :height="headerHeight">
			<div class="df jcfs aic">
				<slot name="topLeftBut">

				</slot>
			</div>
			<div class="df jcfs aic">
				<slot name="topConBut">

				</slot>
			</div>
			<div class="df jcfe aic">
				<el-button type="primary" @click="searchShow()" v-if="searchOff">搜索</el-button>
				<slot name="topRightBut">

				</slot>
			</div>
		</el-header>

		<slot></slot>

		<el-footer class="df jcsb aic" :height="footerHeight" v-if="pageOffs">

			<div v-if="pageNoIs">{{ pageSumCou }}条</div>
			<div v-else class="df jcfs aic">
				<el-pagination :layout="layout" v-model:current-page="mainPage" v-model:page-size="mainSize"
					:page-sizes="sizeList" size="small" :total="pageSumCou" @update:pageSize="sizeChange"
					@update:currentPage="pageChange" />

			</div>

			<div class="df jcfe aic">
				<slot name="bottomRightBut">

					<template v-if="cxeclOff">
						<cxeclBut 
							v-if="way == 'el'" 
							:name="name" 
							:urlKey="urlKey" 
							:nameKey="nameKey" 
							:mianPageCou="mianPageCou"
						>
						</cxeclBut>
						<cxeclButVxe 
							v-if="way == 'vex'" 
							:name="name" 
							:urlKey="urlKey" 
							:nameKey="nameKey"
							:mianPageCou="mianPageCou" 
							:pageObj="pageObj"
						></cxeclButVxe>
					</template>

				</slot>
			</div>

		</el-footer>


		<div class="progressBar df jcc aic" v-show="bar.show">

			<div class="progressBarMain">
				<div class="progressBarMainTitle">{{ bar.one }} / {{ bar.two == 10000 ? '无限' : bar.two }}</div>
				<div class="progressBarMainBar">
					<div class="progressBarMainBarNext" :class="'b_' + bar.titleClass"
						:style="'width:' + (bar.one / bar.two * 100) + '%;'"></div>
				</div>
				<div class="progressBarMainText" :class="'c_' + bar.titleClass">{{ bar.title }}</div>
			</div>

		</div>

	</div>
</template>

<script>
import cxeclBut from '@/components/coom/mianPage/cxeclBut.vue'
import cxeclButVxe from '@/components/coom/mianPage/cxeclButVxe.vue'
import goodsType from '@/components/coom/tree/goodsType.vue'
export default {
	name: 'pageList',

	components: {
		cxeclBut,
		cxeclButVxe,
		goodsType
	},

	props: {

		name: { types: String, default: 'mianPage' }, // 组件名称
		pageObj: { types: Object, default: () => ({}) }, // 页面this
		way: { types: String, default: 'el' },
		url: { types: String, default: '' },
		tableData: { types: Array, default: () => '' }, // 表格数据
		sizes: { types: Array, default: [10, 20, 40, 50, 60, 70, 80, 90, 100] },
		size: { types: Number, default: 60 },
		maxDataOff: { types: Boolean, default: false }, // 是否大数据量加载
		cxeclOff: { types: Boolean, default: true }, // 是否可导出表格
		createdOff: { types: Boolean, default: true }, // 是否创建时加载

		headerHeight: {types: String,default: '40px'}, // 头部高度
		footerHeight: {types: String,default: '40px'}, // 尾部高度

		loadPageCou: {types: Boolean,default: true}, // 是否获取总条数
		load: {types: Boolean,default: true}, // setData 强制加载

		pageOff: { types: Boolean, default: true }, // 是否展示分页
		pageNoIs: {types: Boolean,default: false}, // 不分页
		pageStep: { types: Number, default: 50 }, // 步长

		datas: {types: Object,default: () => ({})}, // 默认请求数据
		searchOff: { types: Boolean, default: true }, // 开启搜索
		search: { // 搜索弹窗
			types: Object,
			default: {
				size: 'auto',
				direction: 'ttb',
			}
		}
	},

	emits: ['update:datas', 'update:tableData', 'pageTableDataUpdate'],

	watch: {
		// datas(newVal, oldVal) {
		datas() {
			this.setFormData();
			// console.log(`${oldVal} 变 ${newVal}`);  
		},

		maxAjaxLoad() {
			this.getMaxData();
		},
	},

	data() {

		var layout = "total, sizes, prev, pager, next, jumper"
		if (this.$store.state.isMobile) {
			layout = "total, sizes, prev, next, jumper"
		}

		return {
			pageOffs: this.pageOff, // 分页开关
			pageSumCou: 0, // 总条数

			maxAjaxLoad: 0, // 最大ajax请求标识检测标识
			maxAjaxStep: this.pageStep, // 步涨
			maxAjaxPage: 1, // 页码
			maxAjaxDataSize: 0, // 本次请求最大数据量
			maxAjaxDataArr: [], // 本次请求数据

			sizeList: this.sizes, // 显示条数列表
			mainPage: 1, // 当前页码
			mainSize: 1, // 当前页码
			dataObj: {
				page: 1,
				size: this.size,
			},

			bar: {
				show: false,
				one: 0,
				two: 10,
				title: '正在执行中',
				titleClass: 'c_lv',
			},

			layout: layout,
			show: false,
			searchs: {},

			mianPageCou: 0,
			headerArr: [],
			headerList: {},
			headerChe: [],
			headerLen: 0,

			urlKey: '',
			nameKey: '',

		}
	},

	created() {

		this.mianPageCou = this.util.jsonObj(this.$store.state.mianPageCou) + 1;
		this.$store.state.mianPageCou += 1;

		this.urlKey = this.$route.query.urlKey;
		this.nameKey = this.urlKey + '_' + this.name;
		console.log('组件名称:', this.nameKey);

		if (this.maxDataOff) {
			this.sizeList = [50, 100, 200, 300, 400, 500, 700, 900, 1200];

			if (this.pageNoIs) { // 不分页
				this.mainSize = 10000;
				this.maxAjaxDataSize = this.mainSize;
			} else {
				var pageObjMaxSize = this.util.localGet('pageObjMaxSize') || {};
				this.mainSize = pageObjMaxSize[this.nameKey] || 50;
				this.maxAjaxDataSize = this.mainSize;
			}

			console.log('默认页面大小:', this.maxAjaxDataSize);
			this.maxAjaxDataArr = [];
		} else {
			var pageObjSize = this.util.localGet('pageObjSize') || this.size;
			this.mainSize = pageObjSize[this.nameKey] || this.size;
			this.dataObj.size = this.mainSize;
			console.log('默认页面大小:', pageObjSize);
		}

		if (this.createdOff) {
			this.setData(this.datas, this.load, this.loadPageCou, this.pageNoIs);
		}

	},

	methods: {

		searchShow() {
			this.show = true;
		},

		onSubmit() {
			this.show = false;
			this.setData({}, this.load, this.loadPageCou, this.pageNoIs, false);
		},

		setLoadData() {
			this.setData(this.datas, true, this.loadPageCou, this.pageNoIs, this.searchOff);
		},

		setDatas(datas) {
			this.setData(datas, this.load, this.loadPageCou, this.pageNoIs, this.searchOff);
		},

		setFormData() {
			this.setData(this.datas, this.load, this.loadPageCou, this.pageNoIs, this.searchOff);
		},

		/**
		 * 初始化数据获取条件参数
		 * @param {Object} dataObj 获取数据的条件 {is_hot:1} 
		 * @param {Boolean} load 调用此方法时是否加载数据 默认：true
		 * @param {Boolean} loadPageCou 使用先获取总条数 后 再获取数据列表 默认：true
		 * @param {Boolean} pageNoIs 是否分页 默认：false
		 * @param {Boolean} searchOff 是否更新搜索条件 默认：true
		 */
		setData(dataObj = {}, load = true, loadPageCou = true, pageNoIss = false, searchOff = true) {
			this.pageSumCou = 0

			if (dataObj['page']) {
				this.dataObj.page = dataObj.page;
				this.mainPage = dataObj.page;
			} else {
				this.dataObj.page = 1;
				this.mainPage = 1;
			}

			if (dataObj['size']) {
				this.dataObj.size = dataObj.size;
				this.mainSize = dataObj.size;
				this.maxAjaxDataSize = this.util.jsonObj(this.mainSize);
			} else {
				this.dataObj.size = this.util.jsonObj(this.mainSize);
				this.maxAjaxDataSize = this.util.jsonObj(this.mainSize);
			}

			for (var k in dataObj) {
				if (
					k != 'page'
					&& k != 'size'
				) {
					this.dataObj[k] = dataObj[k];
				}
			}

			

			if (load) {

				if (this.maxDataOff) {

					if (this.pageNoIs) { // 不分页
						this.mainSize = 10000;
						this.maxAjaxDataSize = this.mainSize;
					}

					this.setBar({
						title: '加载中',
						titleClass: 'lv',
						two: this.maxAjaxDataSize,
						one: 0,
						show: true,
					});

					this.maxAjaxPage = 1;
					this.maxAjaxDataArr = [];
					this.getMaxData();
				} else {
					this.getData();

					if (pageNoIss) {
						this.dataObj.pageNoIs = 1
					} else {
						this.dataObj.pageNoIs = 0
					}

				}

				if (loadPageCou) {
					this.getPageCou(searchOff);
				}
			}
		},

		// 获取数据列表
		getData() {
			var that = this
			that.dataObj.size = that.mainSize;

			that.util.request({
				url: that.url,
				data: that.dataObj,
				load: true,
				success(res) {
					const data = res.data;
					console.log('查询数据列表',data)

					that.$emit('update:tableData', data.data);
					that.$emit('pageTableDataUpdate', data.data);

				},
				fail(res) {
					console.log('page_fail', res)
				}
			})
		},

		// 大数据量加载
		getMaxData() {
			var that = this

			that.dataObj.page = ((that.mainPage - 1) * (that.maxAjaxDataSize / that.maxAjaxStep)) + that.util.jsonObj(that.maxAjaxPage);
			that.dataObj.size = that.util.jsonObj(that.maxAjaxStep);

			that.util.request({
				url: that.url,
				data: that.dataObj,
				load: false,
				success(res) {

					const data = res.data.data;
					data.forEach(function (item) {
						that.maxAjaxDataArr.push(item)
					})
					console.log('查询数据列表',that.maxAjaxDataArr.length)
					that.$emit('update:tableData', that.maxAjaxDataArr);
					that.$emit('pageTableDataUpdate', that.maxAjaxDataArr);

					// console.log('查询数据列表', that.maxAjaxStep)
					if (data.length < that.maxAjaxStep) {
						that.setBarAdd(that.maxAjaxDataSize);
					} else {
						that.setBarAdd(that.maxAjaxStep);

						if ((that.maxAjaxPage * that.maxAjaxStep) < that.maxAjaxDataSize) {
							that.maxAjaxPage += 1; // 页码
							that.maxAjaxLoad += 1; // 最大ajax请求标识检测标识
						}
					}

				},
				fail(res) {
					that.maxAjaxLoad += 1; // 最大ajax请求标识检测标识
					console.log('page_fail', res)
				}
			})

		},

		// 获取总条数
		getPageCou(searchOff = true) {
			var that = this

			var dataCanObj = that.util.jsonObj(that.dataObj);

			dataCanObj['pageCouIs'] = 1;
			dataCanObj['pageNoIs'] = 1;

			that.util.request({
				url: that.url,
				data: dataCanObj,
				load: false,
				success(res) {
					const data = res.data;
					console.log('查询数据总条数',data)
					that.pageSumCou = parseInt(data.dataCou)

					if (that.maxDataOff) {
						if (that.maxAjaxDataSize == 10000) {
							that.maxAjaxDataSize = that.pageSumCou;
							that.mainSize = that.maxAjaxDataSize;
							that.setBarTwo(that.util.jsonObj(that.pageSumCou));
						}
					}

					// eq number like dataTime_qujian data_qujian batch sum_max_min
					if (searchOff) {
						for (var k in data.search) {
							if (data.search[k].way == 'sum_max_min') {
								that.dataObj[k] = {
									min: 0,
									max: 0,
								};
							} else {
								that.dataObj[k] = '';
							}
						}

						// console.log('搜索条件更新',data.search)
						that.searchs = data.search;
					}

				},
				fail(res) {
					console.log('pagel_cou', res)
				}
			})
		},

		// 每页显示条数改变时触发
		sizeChange(size) {

			if (this.maxDataOff) {
				this.mainSize = size;
				var pageObjMaxSize = this.util.localGet('pageObjMaxSize') || {};
				pageObjMaxSize[this.nameKey] = size;
				this.util.localSet('pageObjMaxSize', pageObjMaxSize)

				this.maxAjaxDataSize = size;
				console.log('目标加载数量', this.maxAjaxDataSize)
				this.setBar({
					title: '加载中',
					titleClass: 'lv',
					two: this.maxAjaxDataSize,
					one: 0,
					show: true,
				});

				// 初始化数据获取条件参数
				this.maxAjaxPage = 1;
				this.maxAjaxDataArr = [];
				this.getMaxData();
			} else {
				this.dataObj.size = size
				var pageObjSize = this.util.localGet('pageObjSize') || {};
				pageObjSize[this.nameKey] = this.dataObj.size;
				this.util.localSet('pageObjSize', pageObjSize)

				// 初始化数据获取条件参数
				this.getData();
			}

		},

		// 当前页码改变时触发
		pageChange(page) {

			if (this.maxDataOff) {
				this.mainPage = page;

				this.setBar({
					title: '加载中',
					titleClass: 'lv',
					two: this.maxAjaxDataSize,
					one: 0,
					show: true,
				});

				// 获取数据列表
				this.maxAjaxPage = 1;
				this.maxAjaxDataArr = [];
				this.getMaxData();
			} else {
				this.dataObj.page = page

				// 获取数据列表
				this.getData();
			}

		},

		// 进度条-------------------
		setBar(val = {}) {

			this.bar = {
				show: false,
				one: 0,
				two: 10,
				title: '正在执行中',
				titleClass: 'c_lv',
			}

			if (val['one']) {
				this.bar.one = val.one;
			}

			if (val['two']) {
				this.bar.two = val.two;
			}

			if (val['title']) {
				this.bar.title = val.title;
			}

			if (val['titleClass']) {
				this.bar.titleClass = val.titleClass;
			}

			if (val['show']) {
				this.bar.show = val.show;
			}

			console.log('进度条', this.bar)
		},

		setBarCancel() {
			this.bar.show = false;
		},

		setBarAdd(val = 1) {
			this.bar.one += val;
			console.log('------条码加载----K')
			console.log('加载', this.bar.one)
			console.log('目标', this.bar.two)
			console.log('------条码加载----E')
			if (this.bar.one >= this.bar.two) {
				this.setBarCancel();
			}
		},

		setBarTwo(val = 1) {
			this.bar.two = val;
		},

	}
}
</script>

<style lang="less">

.pageList{
	.progressBar{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background:rgba(0,0,0,0.5);
		z-index: 9999;

		.progressBarMain{
			width: 50%;
			height: auto;

			.progressBarMainTitle{
				width:100%;
				height: 80px;
				line-height: 80px;
				font-size: 60px;
				text-align: center;
				color: #fff;
				font-weight: bold;
			}

			.progressBarMainText{
				width:100%;
				height: 60px;
				line-height: 60px;
				font-size: 40px;
				text-align: center;
				font-weight: bold;
			}

			.progressBarMainBar{
				width:100%;
				height: 20px;
				line-height: 20px;
				border-radius: 100px;
				background:#888;
				border:2px solid #fff;
				overflow: hidden;

				.progressBarMainBarNext{
					width:50%;
					height: 100%;
					border-radius: 100px;
				}
			}
		}

	}
}

</style>